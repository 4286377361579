import { Spacer } from '../../../parts/Spacer';
import { ButtonLinkPrimary } from '../../buttons/ButtonLinkPrimary';
import { ButtonLinkSecondary } from '../../buttons/ButtonLinkSecondary';
import { H2uRedImage } from '../../svgImages/H2uRedImage';
import { mapErrorMessage } from '../mapErrorMessage';
import type { Config } from 'u-next/config';

export const H2uCustomError: React.FC<{
  config: Config;
  statusCode: number;
}> = ({ config, statusCode }) => {
  const { title, texts } = mapErrorMessage(statusCode);

  return (
    <>
      <div className="tw-fixed tw-inset-0 tw-bg-brand-lv1" />
      <div className="tw-h-full tw-p-[0.5rem]">
        <div className="tw-relative tw-bg-base-lv1 tw-pb-[4rem]">
          <div className="tw-w-full-space tw-relative tw-m-auto tw-h-full tw-max-w-[40rem] ">
            <div className="tw-grid tw-grid-rows-[8rem,1fr] tw-gap-[2rem]">
              <div className="tw-relative">
                <H2uRedImage
                  alt=""
                  className="tw-absolute tw-inset-0 tw-m-auto tw-h-[4rem] tw-w-auto"
                />
              </div>
              <div>
                <div className="tw-text-h3 tw-font-bold tw-text-main-100">
                  {title}
                </div>
                <Spacer level={1} />
                <div className="tw-text-base1">{texts}</div>
                <Spacer level={3} />
                <div className=" tw-mx-auto tw-w-full tw-max-w-[20rem]">
                  <div className="tw-grid tw-grid-cols-1 tw-gap-[1rem]">
                    <div>
                      <ButtonLinkPrimary
                        href={config.HELLOERO}
                        text="ハローエロ"
                      />
                    </div>
                    <div>
                      <ButtonLinkSecondary
                        href={config.ACCOUNT}
                        text="アカウント"
                      />
                    </div>
                    <div>
                      <ButtonLinkSecondary href={config.HELP} text="ヘルプ" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

'use client';
import { useUserInfo } from '../../../../../hooks/useUserInfo';

export const UserName: React.FC = () => {
  const { isLoadedUserInfo, userInfo } = useUserInfo();

  return (
    <div className="tw-line-clamp-2 tw-text-right tw-text-base2 tw-leading-[1.2] tw-text-main-80">
      {isLoadedUserInfo && userInfo.isLoggedIn ? userInfo.userName : ''}
    </div>
  );
};

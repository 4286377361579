import { useRouter } from 'next/navigation';
import React from 'react';
import { useUserInfo } from '@/shared/hooks/useUserInfo';

type State = {
  pageStatus: 'INIT' | 'REDIRECT_TO_MEMBER' | 'GUEST_TOP';
};

type Action = {
  type: 'INITIALIZED';
  payload: {
    isLoggedIn: boolean;
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZED': {
      const { isLoggedIn } = action.payload;
      return {
        ...state,
        pageStatus: isLoggedIn ? 'REDIRECT_TO_MEMBER' : 'GUEST_TOP',
      };
    }
  }
};

type UseSiteTop = {
  state: State;
};

export function useSiteTop(): UseSiteTop {
  const router = useRouter();
  const { isLoadedUserInfo, userInfo } = useUserInfo();

  const [state, dispatch] = React.useReducer(reducer, {
    pageStatus: 'INIT',
  });

  React.useEffect(() => {
    switch (state.pageStatus) {
      case 'INIT': {
        if (!isLoadedUserInfo) {
          return;
        }
        dispatch({
          type: 'INITIALIZED',
          payload: {
            isLoggedIn: userInfo.isLoggedIn,
          },
        });
        return;
      }

      case 'REDIRECT_TO_MEMBER': {
        router.replace('/settings');
        return;
      }
    }
  }, [isLoadedUserInfo, router, state.pageStatus, userInfo.isLoggedIn]);

  return {
    state,
  };
}

'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import React from 'react';
import type { CustomWindow } from 'u-next/window';

const GA_MEASUREMENT_ID = 'G-81ZW4FPFG7';

const GlobalScripts: React.FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  React.useEffect(() => {
    if (!(window as CustomWindow).gtag) {
      return;
    }

    (window as CustomWindow).gtag('config', GA_MEASUREMENT_ID, {
      page_title: window.document.title,
      page_location: window.location.href,
    });
  }, [pathname, searchParams]);

  return (
    <>
      <Script
        id="gtag_js"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script
        id="gtag_tag"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
          `,
        }}
      />
    </>
  );
};

export default GlobalScripts;

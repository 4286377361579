'use client';
import { ServiceCardHelloEro } from '@/localShared/components/parts/ServiceCardHelloEro';
import { FormSubmitButtons } from '@/shared/components/features/form/FormSubmitButtons';
import { Loading } from '@/shared/components/parts/Loading';
import { MainContainer } from '@/shared/components/parts/MainContainer';
import { Spacer } from '@/shared/components/parts/Spacer';
import { useSiteTop } from './index.hook';

export const SiteTop: React.FC = () => {
  const { state } = useSiteTop();

  return (
    <>
      {(state.pageStatus === 'INIT' ||
        state.pageStatus === 'REDIRECT_TO_MEMBER') && <Loading />}

      {state.pageStatus === 'GUEST_TOP' && (
        <MainContainer>
          <Spacer level={3} />
          <h2 className="tw-text-h4 tw-font-bold">サービス</h2>
          <Spacer level={1} />
          <div>
            <ServiceCardHelloEro />
          </div>
          <Spacer level={3} />
          <FormSubmitButtons
            buttons={[
              {
                buttonType: 'link',
                text: '無料でアカウント登録',
                href: '/signup',
              },
              {
                buttonType: 'link',
                text: 'ログイン',
                href: '/login',
                buttonStyle: 'secondary',
              },
            ]}
          />
          <Spacer level={4} />
        </MainContainer>
      )}
    </>
  );
};

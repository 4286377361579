import Link from 'next/link';
import { appPublicConfig } from '@/configs/appPublicConfig';
import { HelloeroBlackImage } from '@/shared/components/features/svgImages/HelloeroBlackImage';

export const ServiceCardHelloEro: React.FC = () => (
  <Link
    href={appPublicConfig.HELLOERO}
    passHref
    className="tw-block tw-rounded-4 tw-border tw-border-main-40 tw-px-[1rem] tw-py-[1.5rem]"
  >
    <div className="tw-grid tw-grid-cols-1 tw-items-center tw-gap-[1rem] tablet:tw-grid-cols-[1fr,40%]">
      <div className="tw-text-h5 tw-text-main-100">
        シンプルに、カジュアルに。
        <br />
        アダルトコンテンツを楽しもう。
      </div>
      <div>
        <HelloeroBlackImage alt="HELLOERO" className="tw-w-full" />
      </div>
    </div>
  </Link>
);

export function mapErrorMessage(statusCode: number) {
  const title =
    statusCode === 404
      ? 'お探しのページが見つかりません'
      : '予期せぬエラーが発生しました';

  const texts =
    statusCode === 404
      ? [
          '入力したアドレスが間違っているか、ページが移動した可能性があります。',
          `（${statusCode}）`,
        ]
      : [
          'サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。しばらく時間をおいてから、もう一度アクセスしてください。',
          `（${statusCode}）`,
        ];

  return {
    title,
    texts,
  };
}

import Link from 'next/link';
import { AddWhiteImage } from '../../svgImages/AddWhiteImage';
import { AmazonWhiteImage } from '../../svgImages/AmazonWhiteImage';
import { PlayWhiteImage } from '../../svgImages/PlayWhiteImage';

export const ButtonLinkPrimary: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
    text: string;
    icon?: 'amazon' | 'add' | 'play';
  }
> = ({ href = '', text, icon = null, ...attr }) => (
  <Link href={href} legacyBehavior>
    <a
      {...attr}
      className="tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-full tw-justify-center tw-rounded-[0.2rem] tw-px-[1rem] tw-py-[0.5rem]"
    >
      <div className="tw-absolute tw-inset-0 tw-rounded-[0.2rem] tw-bg-brand-lv1" />
      <div className="tw-relative tw-grid tw-w-fit tw-grid-cols-[auto,1fr] tw-items-center tw-gap-[0.25rem]">
        {icon === 'amazon' && (
          <div>
            <AmazonWhiteImage
              alt={text}
              className="tw-h-[1.5rem] tw-w-[1.5rem] tw-object-contain"
            />
          </div>
        )}
        {icon === 'add' && (
          <div>
            <AddWhiteImage
              alt={text}
              className="tw-h-[1.5rem] tw-w-[1.5rem] tw-object-contain"
            />
          </div>
        )}

        {icon === 'play' && (
          <div>
            <PlayWhiteImage
              alt={text}
              className="tw-h-[1.5rem] tw-w-[1.5rem] tw-object-contain"
            />
          </div>
        )}
        <div className="tw-text-button tw-text-base-lv1 tw-transition-colors group-hover:tw-text-main-100">
          {text}
        </div>
      </div>
    </a>
  </Link>
);
